export const getQuestion = (poll, locale) => {
  if (locale.match(/^(en-GB|ar-SA|de-DE|es-ES|fr-FR|id-ID|it-IT|ko-KR|pt-BR|ja-JP)$/)) {
    return poll.question.filter(question => question.locale === locale)[0];
  }
  else {
    return poll.question.filter(question => question.locale === "en-GB")[0];
  }
}


export const getPollChoices = (poll, locale, type) => {
  let choices = [];
  var i;
  for(i = 0; i < poll.pollChoices.length; i++){
    let choice;

    if (locale.match(/^(en-GB|ar-SA|de-DE|es-ES|fr-FR|id-ID|it-IT|ko-KR|pt-BR|ja-JP)$/)) {
      choice = poll.pollChoices[i].text.filter(option =>option.locale === locale)[0];
    }
    else {
      choice = poll.pollChoices[i].text.filter(option =>option.locale === "en-GB")[0];
    }

    if(type == "Lineups"){
      choices.push({ 'id': poll.pollChoices[i].id, 'option' : choice });
    }
    else {
      choices.push({ 'id': poll.pollChoices[i].id, 'imageURL': poll.pollChoices[i].metadata.promiseImageUrl ? poll.pollChoices[i].metadata.promiseImageUrl.replace("{LOCALE}", locale.split('-')[0].toUpperCase()) : null,'option' : choice });
    }
  }
  return choices;
}

export const isIOS = () => {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)
  ) {
    return true;
  }
    return false;
};

