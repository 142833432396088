import React, { useEffect, useState, createRef } from 'react';
import { useStateContext } from '../../state';
// import { isIOS } from '../../helpers';
// import domtoimage from 'dom-to-image';
// import { toPng } from 'html-to-image';
import Lockup from '../../assets/lockup-white.png';
import ShareBG from '../../assets/share_button.png';
import { ShareButton } from './styles';
import { ModalDiv } from './styles';

export const ShareModal = (props) => {

  const { choiceImageURL } = useStateContext();
  const [downloading, setDownloading] = useState(false);
  const ref = createRef(null);

  const handleShare = (url) => {
    setDownloading(true);
    const link = document.createElement('a')
    link.download = 'FIFA-WC-2022-poll-promise.jpeg'
    link.href = url;
    link.target = "_blank"
    link.click();
    setDownloading(false);
  }

  // const onShareClick = useCallback(() => {
  //   setDownloading(true);
  //   let refr = ref.current;

  //   if (ref.current === null) {
  //     return
  //   }

  //   else {

  //     if(isIOS()){
  //       domtoimage.toJpeg(refr, {
  //         cacheBust: true,
  //         bgcolor : '#550065',
  //       })
  //       .then(dataURL => {
  //         domtoimage.toJpeg(refr, {
  //           cacheBust: true,
  //           bgcolor : '#550065',
  //         })
  //         .then(dataURL2 => {
  //           const link = document.createElement('a')
  //           link.download = 'FIFA-WC-2022-match-prediction.jpeg'
  //           link.href = dataURL2;
  //           link.click();
  //           setDownloading(false)
  //         })
  //       });
  //     }

  //     else {
  //       toPng(refr, { cacheBust: true, backgroundColor : '#550065' })
  //       .then((dataURL) => {
  //         const link = document.createElement("a");
  //         link.download = "FIFA-WC-2022-match-prediction.png";
  //         link.href = dataURL;
  //         link.click();
  //         setDownloading(false)
  //        })
  //     }
  //   }
  // }, [ref])

  return (
    <ModalDiv show={props.show}>
      <section>
        <div className='lockup'>
          <img src={Lockup}  alt="World Cup Logo" />
        </div>
        <div className='promise'>
          <img ref={ref} src={choiceImageURL} alt="Poll Promise" />
          <h2>Thank you for your vote</h2>
        </div>
        {
          downloading ? <ShareButton bg={ShareBG}>Downloading...</ShareButton> :
          <ShareButton bg={ShareBG} onClick={ () => handleShare(choiceImageURL)}>Download</ShareButton>
        }
        
      </section>
    </ModalDiv>
  )
}
