import { getPollChoices, getQuestion } from './../helpers';


export const reducer = (state, action) => {
  switch (action.type) {

    case 'SET_POLL': {
      return {
        ...state,
        poll: action.data,
        question: getQuestion(action.data, action.locale),
        pollChoices: getPollChoices(action.data, action.locale, action.pollType),
        type: action.pollType,
        matchCentreUrl: action.matchCentreUrl
      }
    }

    case 'SET_VOTE': {
      console.log(state.type)
      return {
        ...state,
        voted: action.value,
        choiceId: action.id,
        choiceText: action.text,
        choiceImageURL: action.image,
        showVoteCompletedModal: state.type != "Lineups" ? true : false
      }
    }

    case 'CLEAR_VOTE': {
      return {
        ...state,
        voted: action.value,
        choiceId: null, 
        showVoteCompletedModal: false
      }
    }

    default:
      return state;
  }
}
