import React, {useEffect} from 'react';
import { useStateContext, useStateDispatchContext } from '../../state';
import { ModalDiv, AnswerSelectDiv } from './styles';
import CloseIcon from '../../assets/close.png';
import QuestionBG from '../../assets/question_bg.png';
import { ShareModal } from '../share-modal';

export const StartModal = () => {

  const { poll, question,  pollChoices, voted, showVoteCompletedModal, matchCentreUrl, choiceId} = useStateContext();
  const { fetchPoll, submitVote, dispatch } = useStateDispatchContext();

  useEffect(() => {
    fetchPoll();
  }, []); 

  const handleVote = (vote_choice) => {
    submitVote(vote_choice);
  }

  const handleClose = () => {
    dispatch({ type: 'CLEAR_VOTE', value: false });
  }

  return (
    <ModalDiv>
      <section>
        { poll ? 
          <>
            <div className="modal_body">
              <section>
                <img className='bg' src={QuestionBG} alt="Question BG" /> 
                <h1>{question.value}</h1>
              </section>

              <AnswerSelectDiv>
                {pollChoices.map((choice, id) => (
                  <div key={choice.id} onClick={() => handleVote(choice)} > 
                    <p>{choice.option.value}</p>
                  </div>
                ))}
              </AnswerSelectDiv>
              
              <p>Tap a button to select your choice</p>
            </div>
            { voted ? 
              <div className="modal_footer">
                <h2>Thank you for your vote!</h2>
                <button onClick={() => window.location.href = matchCentreUrl }>Go to Match Centre</button>
              </div> : null }
          </> :
          <p>loading</p> 
        }
      </section>
      {
        showVoteCompletedModal ? <ShareModal /> : null
      }
    </ModalDiv>
  )
}
