import styled from 'styled-components';

export const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  visibility: visible;
  opacity: 1;
  top: 0;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  padding: 0;
  background: rgba(244,0,9,1);
  text-align: center;
  z-index: 40;

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0;
    position: relative;
    font-family: QatarBold;
    color: white;
    padding-top: 3rem;

    .modal_header {
      display: flex;
      position: fixed;
      z-index: 20;
      margin: 0;
      top: 0;

      img {
        width: 2rem;
        position: fixed;
        margin: 1rem 1.5rem;
        top: 0;
        right: 0;
      }
    }

    .modal_body {
      height: 100%;
      position: fixed;
      top: 0;
      z-index: 11;

      section {
        margin: 0;
      }

      .bg {
        position: relative;
        width: 95%;
      }

      h1 {
        font-size: 1.5rem;
        text-align: center;
        max-width: 66%;
        margin-top: -5rem;
        margin-left: 5rem;
        color: #F40009;
        z-index: 20;
      }
      
    }

    .modal_footer {
      display: flex;
      flex-direction: column;
      position: fixed;
      margin: 0;
      bottom: 2rem;
      z-index: 15;

      button {
        width: 80%;
        padding: 1rem;
        margin: 0.5rem auto;
        border: none;
        color: #F40009 !important;
        background-color: #fff;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 600;
      }

      h2{
        margin: 0.65rem;
      }
    }
  }
`;

export const AnswerSelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0;

  p {
    padding: 1rem;
    margin: 0.75rem 0;
    color: #F40009 !important;
    background-color: #fff;
    text-align: center;
    font-size: 1.25rem;
  }

  div {
    margin: 0 2rem;
  }
`;
