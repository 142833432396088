import React from 'react'
import { StartModal } from './components/start-modal';
import { StateProvider } from './state';
import { AppDiv, GlobalStyles, Wrapper } from './styles';

function App() {

  return (
    <AppDiv>
      <GlobalStyles />
      <StateProvider>
        <Wrapper>
          <StartModal />
        </Wrapper>
      </StateProvider>
    </AppDiv>
  );
}

export default App;
